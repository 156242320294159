import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  DialogActions,
} from "@mui/material";
import RedesignCard from "./Card";
import DialogBoxYt from "../Widgets/DialogBoxYt";
import DialogBoxVimeo from "../Widgets/DialogBoxVimeo";
import DefaultWidget from "../Widgets/DefaultWidget";
import DialogBoxWebsite from "../Widgets/DialogBoxWebSite";
import DialogBoxEmbed from "../Widgets/DialogBoxEmbed";
import DialogBoxScrollingStrip from "../Widgets/DialogBoxScrollingStrip";
import DialogBoxWeather from "../Widgets/DialogBoxWeather";
import DialogBoxCanva from "../Widgets/DialogBoxCanva";
import DialogBoxPostermywall from "../Widgets/DialogBoxPostermywall";
import DialogBoxSplitScreen from "../Widgets/DialogBoxSplitScreen";
import DialogBoxYoutubePlaylist from "../Widgets/DialogBoxYoutubePlaylist";
import DialogBoxTwitchPlaylist from "../Widgets/DialogBoxTwitchPlaylist";

import useUserFeature from "../../../hooks/use-user-feature";

import { baseImg } from "../../../api/endpoints";
import { useMediaQuery } from "react-responsive";

const widget = {
  default: DefaultWidget,
  youtube: DialogBoxYt,
  vimeo: DialogBoxVimeo,
  website: DialogBoxWebsite,
  embed: DialogBoxEmbed,
  scrollingstrip: DialogBoxScrollingStrip,
  weather: DialogBoxWeather,
  canva: DialogBoxCanva,
  postermywall: DialogBoxPostermywall,
  spiltscreen: DialogBoxSplitScreen,
  youtubeplaylist: DialogBoxYoutubePlaylist,
  twitch: DialogBoxTwitchPlaylist,
};

function CreateWidgetModel({ isOpen, onClose, onAddWidget, onAddContent, onTakeData, setRefreshData }) {
  const { t } = useTranslation();
  const [widgetName, setWidgetName] = useState("default");
  const [widgetIcon, setWidgetIcon] = useState("");
  const [openWidget, setOpenWidget] = useState(false);
  const Component = widget[widgetName];
  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  const widgetsList = [
    {
      id: 1,
      name: "youtube",
      slug: "youtube",
      img: "/images/youtubeIcon.png",
      enabled: useUserFeature("youtube_app"),
    },
    {
      id: 2,
      name: "weather",
      slug: "weather",
      img: "/images/weather-widget.png",
      enabled: useUserFeature("weather_app"),
    },
    {
      id: 3,
      name: "vimeo",
      slug: "vimeo",
      img: "/images/vimeoIcon.png",
      enabled: useUserFeature("vimeo_app"),
    },
    {
      id: 4,
      name: "website",
      slug: "website",
      img: "/images/websiteIcon2.png",
      enabled: useUserFeature("website_app"),
    },
    {
      id: 5,
      name: "embed",
      slug: "embed",
      img: "/images/embedIcon.png",
      enabled: useUserFeature("embed_app"),
    },
    {
      id: 6,
      name: "scrolling Strip",
      slug: "scrollingstrip",
      img: "/images/scrollingStripIcon.png",
      enabled: useUserFeature("scrolling_script_app"),
    },
    {
      id: 7,
      name: "Canva",
      slug: "canva",
      img: "/images/canvaLogo.svg",
      enabled: useUserFeature("canva_app"),
    },
    {
      id: 8,
      name: "Postermywall",
      slug: "postermywall",
      img: "/images/postermywallLogo.png",
      enabled: useUserFeature("postermywall_app"),
    },
    {
      id: 9,
      name: "Spilt Screen",
      slug: "spiltscreen",
      img: "/images/VCPsplitscreenlogo.png",
      enabled: useUserFeature("split_screen"),
    },
    {
      id: 10,
      name: "Youtube Playlist",
      slug: "youtubeplaylist",
      img: "/images/yt_app.png",
      enabled: useUserFeature("yt_playlist"),
    },
    {
      id: 11,
      name: "Twitch",
      slug: "twitch",
      img: "/images/twitch_app.png",
      enabled: useUserFeature("twitch_app"),
    },
  ];
  const onOpenWidgetFile = (obj) => {
    setOpenWidget(true);
    setWidgetName(obj.slug);
    setWidgetIcon(obj.icon);
    onClose();
  };
  return (
    <>
      <Component
        isNew={true}
        onAddWidget={onAddWidget}
        setRefreshData={setRefreshData}
        onAddContent={onAddContent}
        isOpen={openWidget}
        onTakeData={(data) => onTakeData(data)}
        onClose={(close) => setOpenWidget(close)}
        isIcon={widgetIcon}
      />
      <Dialog open={isOpen} onClose={() => onClose(false)}>
        <DialogTitle>{t("views.createWidgetModel.selectApp")}</DialogTitle>
        <DialogContent
          sx={{
            width: isMobile ? "280px" : "600px",
            height: isMobile ? "320px" : "610px",
          }}
        >
          <Box
            sx={{
              height: isMobile ? "300px" : "590px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <form encType="multipart/form-data">
              <input
                type={"file"}
                hidden
                id="click-browser-computer"
                multiple
                //   onChange={fileUpload}
              />
            </form>

            <Typography
              variant={"caption"}
              sx={{
                color: "#92959E",
                fontFamily: "open sans",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                paddingTop: isMobile ? "260px":"210px",
              }}
            >
              {t("views.createWidgetModel.customizeScreen")}
            </Typography>
            <Box
              sx={{
                width: isMobile ? "240px" : "490px",
                margin: "10px auto",
                borderRadius: "10px",
                display: "flex",
                flexWrap: "wrap",
                gap: isMobile ? "8px" : "20px",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "row",
              }}
            >
              {widgetsList.map((widget) => (
                <RedesignCard
                  widget={widget}
                  onClickWidget={onOpenWidgetFile}
                  disabled={!widget.enabled}
                />
              ))}
            </Box>
          </Box>
          </DialogContent>
          <DialogActions sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
          }}>
            <Button onClick={() => onClose(false)}>
              {t("views.createWidgetModel.close")}
            </Button>
          </DialogActions>
      </Dialog>
    </>
  );
}

export default CreateWidgetModel;

import { COLORS } from "./types";
let usedColors = [];
let lastColor = null;

export const getRandomColor = () => {
  if (usedColors.length === COLORS.length) {
    usedColors = [];
  }

  let availableColors = COLORS.filter(
    color => !usedColors.includes(color) && color !== lastColor
  );

  // Jeśli wszystkie kolory są zajęte, pozwól wybrać ponownie
  if (availableColors.length === 0) {
    availableColors = COLORS.filter(color => color !== lastColor);
    usedColors = [];
  }

  const randomIndex = Math.floor(Math.random() * availableColors.length);
  const selectedColor = availableColors[randomIndex];

  usedColors.push(selectedColor);
  lastColor = selectedColor;
  return selectedColor;
};

import React from "react";

import { HorizontalLayout } from 'src/layouts/dashboard/horizontal-layout';
import { VerticalLayout } from 'src/layouts/dashboard/vertical-layout';

import { SettingsButton } from 'src/components/settings/settings-button';
import { SettingsDrawer } from 'src/components/settings/settings-drawer';

import { useSettings } from 'src/hooks/use-settings';
import { useSections } from 'src/layouts/dashboard/config';

import { ErrorBoundary } from 'react-error-boundary';

import { useJsApiLoader } from "@react-google-maps/api";

export default function Layout({ children, ...props }) {

  const settings = useSettings();
  const sections = useSections();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const layoutChildren = (
    <div >
      <ErrorBoundary fallback={null}>
        {isLoaded && children}
      </ErrorBoundary>
      <SettingsButton onClick={settings.handleDrawerOpen} />
      <SettingsDrawer
        canReset={settings.isCustom}
        onClose={settings.handleDrawerClose}
        onReset={settings.handleReset}
        onUpdate={settings.handleUpdate}
        open={settings.openDrawer}
        values={{
          colorPreset: settings.colorPreset,
          contrast: settings.contrast,
          direction: settings.direction,
          paletteMode: settings.paletteMode,
          responsiveFontSizes: settings.responsiveFontSizes,
          stretch: settings.stretch,
          layout: settings.layout,
          navColor: settings.navColor,
        }}
      />
    </div>
  );

  if (settings.layout === 'horizontal') {
    return (
      <HorizontalLayout
        sections={sections}
        navColor={settings.navColor}
        children={layoutChildren}
        {...props}
      />
    );
  }

  return <VerticalLayout
    sections={sections}
    navColor={settings.navColor}
    children={layoutChildren}
    {...props}
  />
};
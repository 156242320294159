import React, { useState, useEffect, useRef } from 'react'
import {TextField, Select, MenuItem, FormControl, InputLabel, Dialog, DialogContent, DialogTitle, Box, DialogActions, IconButton, Stack, Button, Divider} from '@mui/material';
import { IoSettingsOutline } from "react-icons/io5";
import {UnitTypes, ResolutionTypes, BackgroundMusicTypes, AudioZoneTypes, PrimaryZoneTypes} from './types';
import {Typography, Checkbox} from '@mui/material';
import { useTranslation } from "react-i18next";


const DialogBoxSettings = ({isNew,setIsNewState, setSplitScreenSaved, zoneDetails, setZonesDetails, screenInfo, setScreenInfo, settingsInfo, setSettingsInfo, isOpen, onClose}) => {
    const { t } = useTranslation();
    const settingKeys = ["Unit Type", "Resolution", "Background Music", "Audio Zone", "Primary Zone"];
    const settingTypes = [UnitTypes, ResolutionTypes, BackgroundMusicTypes, AudioZoneTypes, PrimaryZoneTypes];

    const [customs, setCustoms] = useState({width: 0, height: 0});
    // const [primaryTimingZones, setPrimaryTimingZones] = useState([]);
    const scrollRef = useRef(null);
    useEffect(() => {
        console.log("Zone details updated:", zoneDetails);
      }, [zoneDetails]);
      
    // ---- Functions ----
    const changeSettings = (key, val) => {
      setIsNewState(true);
      setSplitScreenSaved(false);
    
      // Aktualizowanie `settingsInfo`
      setSettingsInfo((prevSettings) => ({ ...prevSettings, [key]: val }));
    
      // Dostosowanie `zonesDetails` do ustawień
      setZonesDetails((prevZones) => 
        prevZones.map((zone) => {
          let updatedZone = { ...zone };
    
          if (key === "Audio Zone") {
            updatedZone.audio_zone = val === "All" || val === zone.name;
          }
    
          if (key === "Background Music") {
            updatedZone.bg_music_zone = val === zone.name;
          }
    
          if (key === "Primary Zone") {
            updatedZone.primary_zone = val === zone.name;
          }
    
          return updatedZone;
        })
      );
    
      console.log("Settings changed:", key, val);
    };
    

    const handleAddTimingZone = (name) => {
      console.log("Adding timing zone", name);
    
      setZonesDetails((prevDetails) =>
        prevDetails.map((zone) => ({
          ...zone,
          primaryTiming: zone.name === name ? !zone.primaryTiming : zone.primaryTiming,
        }))
      );
    
      setIsNewState(true);
      setSplitScreenSaved(false);
    };
    
    const handleCloseDialog = () => {
      setZonesDetails((prevDetails) =>
        prevDetails.map((zone) => ({
          ...zone,
          audio_zone: settingsInfo["Audio Zone"] === "All" || settingsInfo["Audio Zone"] === zone.name,
          bg_music_zone: settingsInfo["Background Music"] === zone.name,
          primary_zone: settingsInfo["Primary Zone"] === zone.name,
        }))
      );
    
      onClose(false);
      setScreenInfo({
        ...screenInfo,
        customWidth: customs.width,
        customHeight: customs.height,
      });
    };
    
      

      useEffect(() => {
        setCustoms({ width: screenInfo.customWidth, height: screenInfo.customHeight });
      }, []);
    
      useEffect(() => {
        if (settingsInfo["Primary Zone"] !== "None" && scrollRef.current) {
          scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, [settingsInfo["Primary Zone"]]);

    return (
        <Dialog
            open={isOpen}
            onClose={() => handleCloseDialog()}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle sx={{marginBottom:"10px"}}>
                <Stack direction={"column"} sx={{alignItems:"center"}}>
                    <IconButton>
                        <IoSettingsOutline size={"40px"}/>
                    </IconButton>
                    <Box fontSize="h4.fontSize">{t("settings.splitScreenConfiguration")}</Box>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{}}>
                <Stack direction={"column"} sx={{paddingLeft:"30px", paddingRight:"30px"}}>
                    {settingKeys.map((key, index) => (
                        <>
                            {key === "Resolution" && settingsInfo["Resolution"] === "Custom" ? 
                            <>
                                <FormControl fullWidth variant="outlined" sx={{marginTop:"20px"}} key={index}>
                                    <InputLabel id="select-label">{t(`settings.${key.toLowerCase().replace(/\s+/g, '')}`)}</InputLabel>
                                    <Select
                                        labelId="select-label"
                                        value={settingsInfo[key]}
                                        onChange={(e) => changeSettings(key, e.target.value)}
                                        label={t(`settings.${key.toLowerCase().replace(/\s+/g, '')}`)}
                                    >
                                        {Object.entries(settingTypes[index]).map(([optionKey, value]) => (
                                            <MenuItem key={optionKey} value={value}>
                                                {value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Stack direction="row" sx={{marginTop:"20px", gap:"10px"}}>
                                    <TextField
                                        label={t("settings.width")}
                                        variant="outlined"
                                        value={customs.width}
                                        onChange={(e) => setCustoms({...customs, width: e.target.value})}
                                        sx={{width:"50%"}}
                                    />
                                    <TextField
                                        label={t("settings.height")}
                                        variant="outlined"
                                        value={customs.height}
                                        onChange={(e) => setCustoms({...customs, height: e.target.value})}
                                        sx={{width:"50%"}}
                                    />
                                </Stack>
                            </>
                            : key === "Background Music" || key === "Audio Zone" || key === "Primary Zone" ?
                            <FormControl fullWidth variant="outlined" sx={{marginTop:"20px"}} key={index}>
                                <InputLabel id="select-label">{t(`settings.${key.toLowerCase().replace(/\s+/g, '')}`)}</InputLabel>
                                <Select
                                    labelId="select-label"
                                    value={settingsInfo[key]}
                                    onChange={(e) => {
                                        changeSettings(key, e.target.value)
                                    }}
                                    label={t(`settings.${key.toLowerCase().replace(/\s+/g, '')}`)}
                                >
                                    {key === "Audio Zone" ?
                                    ["All", ...zoneDetails.map(zone => zone.name)].map((zone) => (
                                        <MenuItem key={zone} value={zone}>
                                            {zone}
                                        </MenuItem>
                                    ))
                                    : ["None", ...zoneDetails.map(zone => zone.name)].map((zone) => (
                                        <MenuItem key={zone} value={zone}>
                                            {zone}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            :
                            <FormControl fullWidth variant="outlined" sx={{marginTop:"20px"}} key={index}>
                                <InputLabel id="select-label">{t(`settings.${key.toLowerCase().replace(/\s+/g, '')}`)}</InputLabel>
                                <Select
                                    labelId="select-label"
                                    value={settingsInfo[key]}
                                    onChange={(e) => changeSettings(key, e.target.value)}
                                    label={t(`settings.${key.toLowerCase().replace(/\s+/g, '')}`)}
                                >
                                    {Object.entries(settingTypes[index]).map(([optionKey, value]) => (
                                        <MenuItem key={optionKey} value={value}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            }
                        </>
                    ))}
{settingsInfo["Primary Zone"] !== "None" &&
  <div ref={scrollRef} style={{ marginTop:"20px"}}>
    <Typography sx={{fontSize:"14px"}}>{t("settings.selectTimingZones")}</Typography>
    {zoneDetails.length > 0 && zoneDetails.filter((zone) => zone.name !== settingsInfo["Primary Zone"]).map((zone, index) => (
      <Stack direction={"row"} key={index} sx={{alignItems:"center"}}>
        <Checkbox
          checked={zone.primaryTiming || false}
          onChange={() => handleAddTimingZone(zone.name)}
        />
        <Typography>{zone.name}</Typography>
      </Stack>
    ))}
  </div>
}
                </Stack>
            </DialogContent>

            <Divider sx={{ width: '100%', marginTop:"35px" }} />
            <DialogActions sx={{ backgroundColor: "#1A2432", width: "100%", padding:"10px" }}>
                <Button onClick={() => handleCloseDialog()} color="primary" variant="contained">
                    {t("settings.close")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogBoxSettings;
import { Box, Typography, Menu, MenuItem, Card, FormControlLabel, FormGroup, Checkbox, IconButton, SvgIcon } from "@mui/material";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

// icons
import SlideshowIcon from "@mui/icons-material/Slideshow";
import ImageIcon from "@mui/icons-material/Image";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import DotsVerticalIcon from "@untitled-ui/icons-react/build/esm/DotsVertical";
import { FaTwitch } from "react-icons/fa";

function ListViewFile({
  setRefreshData,
  url,
  type,
  name,
  createdAt,
  onAddPlaylist,
  onPushScreen,
  onMove,
  onDelete,
  contentObject,
  onCheckedSingleContent,
  onUncheckedSingleContent,
  onPreview,
  handleSeeSplitScreen,
  handleTwitchPreview,
  handleSeeTwitchDialog,
  handleSeeYoutubeDialog,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSplitScreenDialog, setOpenSplitScreenDialog] = React.useState(false)
  const [savedZones, setSavedZones] = React.useState({})

  // console.log("CONTENT OBJECT: ", contentObject);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    if (type === "add_playlist") {
      onAddPlaylist(obj);
    }
    if (type === "push_screen") {
      onPushScreen(obj);
    }
    if (type === "on_move") {
      onMove(obj.id);
    }
    if (type === "on_preview") {
      console.log("OBJ Preview: ", obj?.type);
      if (obj?.type && obj?.type === "split_screen") {
          handleSeeSplitScreen(contentObject)
          // setOpenSplitScreenDialog(true)
          return;
      }
      if (obj?.widget?.widgetCat === "twitch") {
        handleTwitchPreview(contentObject)
        return;
      }
      if (obj?.widget?.widgetCat === "yt_playlist") {
        window.open(obj.widget.url, "_blank");
        return;
      }
      else{
        onPreview({ url: obj.url, contentType: obj.type, embed: obj.embed, widget: obj.widget });
      }
    }
    if (type === "on_delete") {
      onDelete(obj.id);
    }
    setAnchorEl(null);
  };
  function timeSince(date) {
    var seconds = Math.floor((new Date(Date.now()) - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + "y";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + "mon";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + "d";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + "h";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + "min";
    }
    return Math.floor(seconds) + "sec";
  }
  const onChangeSingleContent = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      onCheckedSingleContent(value);
    } else {
      onUncheckedSingleContent(value);
    }
  };


  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  return (
    <>
      <Card
        sx={{
          backgroundColor: "transparent",
          boxShadow: 0,
          height: "60px",
          width: "100%",
          margin: "5px auto",
          display: "flex",
          transition: (theme) =>
            theme.transitions.create(["background-color, box-shadow"], {
              easing: theme.transitions.easing.easeInOut,
              duration: 200,
            }),
          "&:hover": {
            backgroundColor: "background.paper",
            boxShadow: 16,
          },
        }}
        variant="outlined">
        <Box
          sx={{
            width: isMobile ? "70%" : "50%",
            height: "100%",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            paddingLeft: "20px",
          }}>
        <FormGroup>
            <FormControlLabel control={<Checkbox onChange={onChangeSingleContent} checked={contentObject?.checked ? true : false} value={contentObject.id} />} label="" />
        </FormGroup>

          {type === "split_screen" ? 
          
            <Box sx={{ height: "80%", width: "100px", cursor: "pointer", flexShrink: 0, maxWidth: "60px" }} onClick={()=>{
              handleSeeSplitScreen(contentObject)
              setOpenSplitScreenDialog(true)
              }}>
              <a style={{ color: "white", cursor: "pointer", textDecoration: "none" }} >
                <img src={contentObject.widget?.widgetIcon} style={{ maxWidth: "80%", maxHeight: "100%" }} /> 
              </a>
            </Box>
          
          : 
          (
            contentObject?.widget?.widgetCat === "website" ? (
              <Box sx={{ height: "80%", width: "100px", cursor: "pointer", flexShrink: 0, maxWidth: "60px" }}>
                <a style={{ color: "white", cursor: "pointer", textDecoration: "none" }} href={contentObject.widget.url} target="_blank">
                  <img src={contentObject.widget?.widgetIcon} style={{ maxWidth: "80%", maxHeight: "100%" }} />
                </a>
              </Box>
            ) : contentObject?.widget?.widgetCat === "yt_playlist" ? 
            
              <Box onClick={()=>handleSeeYoutubeDialog(contentObject)} sx={{ height: "80%", width: "100px", cursor: "pointer", flexShrink: 0, maxWidth: "60px" }}>
                  {/* <FaYoutube size={"32px"} style={{ marginTop:"10px", color:"#ff0000"}}><Box style={{backgroundColor:"white"}}/></FaYoutube> */}
                  {/* <img src={contentObject.widget?.widgetIcon} style={{ maxWidth: "80%", maxHeight: "100%" }} /> */}
                  <img src={"/images/yt_app.png"} style={{ maxWidth: "80%", maxHeight: "100%" }} />
              </Box>

              : contentObject?.widget?.widgetCat === "twitch" ? 
                          // /twitchtv?chat=true&channel=lablabai
              <Box onClick={()=>handleSeeTwitchDialog(contentObject)} sx={{ height: "80%", width: "100px", cursor: "pointer", flexShrink: 0, maxWidth: "60px" }}>
                  <FaTwitch size={"32px"} style={{ marginTop:"10px", color:"#9147ff"}}/> 
              </Box>
           : (
              <Box sx={{ height: "80%", width: "100px", cursor: "pointer", flexShrink: 0, maxWidth: "60px" }} onClick={() => handleClose("on_preview", contentObject)}>
                {type === "image" && <img src={url} style={{ height: "100%", width: "100%", objectFit: "contain" }} />}
                {type === "video" && <video src={url} style={{ height: "100%", width: "100%", objectFit: "cover" }} controls></video>}
                {contentObject.embed === "embed" && <embed src={contentObject.url} style={{ height: "100%", width: "100%", objectFit: "cover" }}></embed>}
                {type === "widget" && <img src={contentObject.widget?.widgetIcon} style={{ maxWidth: "80%", maxHeight: "100%" }} />}
              </Box>
            )

          )}
          {}

          <Box>
            <Typography variant="subtitle2">{isMobile ? name.substring(0, 15) : name.substring(0, 35)}</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            width: isMobile ? "30%" : "50%",
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
            paddingRight: "20px",
          }}>
          <Box sx={{ height: "4vh", width: "2vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
            {type === "image" && <ImageIcon color="primary" />}
            {type === "video" && <SlideshowIcon color="primary" />}
            {type === "widget" && <AppRegistrationIcon color="primary" />}
            {type === "split_screen" && <AppRegistrationIcon color="primary" />}
          </Box>

          <Typography color="text.secondary" variant="caption" sx={{ display: { md: "block", xs: "none" } }}>
            {`${t("views.listViewFile.added")} ${timeSince(new Date(createdAt).getTime())} ${t("views.listViewFile.ago")}`}
          </Typography>

          {/* {type !== "split_screen" &&  */}
            <IconButton
              onClick={handleClick}
              aria-controls={open ? "content-menu" : undefined}
              aria-haspopup={"true"}
              aria-expanded={open ? "true" : undefined}
              id="content-menu-btn">
              <SvgIcon fontSize="small">
                <DotsVerticalIcon />
              </SvgIcon>
            </IconButton>
          {/* } */}
          <Menu
            id={"content-menu"}
            anchorEl={anchorEl}
            open={open}
            MenuListProps={{
              "aria-labelledby": "content-menu-btn",
            }}
            onClose={handleClose}
            keepMounted
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}>
              {type !== "split_screen" &&
                <MenuItem onClick={() => handleClose("add_playlist", contentObject)}>{t("views.listViewFile.addToPlaylist")}</MenuItem>
              }
            {/* <MenuItem onClick={() => handleClose("push_screen", contentObject)}>{t("views.listViewFile.pushToScreen")}</MenuItem> */}
            <MenuItem onClick={() => handleClose("on_move", contentObject)}>{t("views.listViewFile.move")}</MenuItem>
            {contentObject?.widget?.widgetCat === "website" ? (
              <MenuItem>
                <a style={{ color: "white", cursor: "pointer", textDecoration: "none" }} href={contentObject?.widget?.url} target="_blank">
                  {t("views.listViewFile.preview")}
                </a>
              </MenuItem>
            ) : (
              <MenuItem onClick={() => handleClose("on_preview", contentObject)}>{t("views.listViewFile.preview")}</MenuItem>
            )}
            {/* <MenuItem onClick={() => handleClose("on_delete", contentObject)}>{t("views.listViewFile.delete")}</MenuItem> */}
          </Menu>
        </Box>
      </Card>
    </>
  );
}

export default ListViewFile;
